<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <span class="form-title">Cadastro Geral</span>

        <div class="row">
          <ed-input-tipo
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.intTipoCategoriaId"
            name="intTipoCategoriaId"
            label="Categoria"
            rules="required"
            tipo="1403"
          />
          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strNome"
            name="strNome"
            label="Nome"
            required
            rules="required"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-auto-complete
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strCodigoEsocial"
            label="Código eSocial"
            name="strCodigoEsocial"
            item-text="strCodigo"
            item-value="strCodigo"
            route="Esocial/EsocialTabelaConteudo"
            :filters="{
              strBusca: this.formData.strCodigoEsocial,
              intEsocialTabelaId: 27,
            }"
            :initializeItems="formData.strCodigoEsocial ? true : false"
          />

          <ed-input-text
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.strCodigoTuss"
            name="strCodigoTuss"
            label="Código TUSS"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.intOrdem"
            name="intOrdem"
            label="Ordem"
            :items="arrayOrdem"
          />

          <ed-input-select
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
            v-model="formData.intInterpretacao"
            name="intInterpretacao"
            label="Interpretação"
            :items="arrayInterpretacao"
          />

          <ed-input-text-area
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strPreparo"
            name="strPreparo"
            label="Preparo do Exame"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-text-area
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.strObservacao"
            name="strObservacao"
            label="Observações"
            :disabled="!formData.boolAtivo"
          />

          <ed-input-switch
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            v-model="formData.boolAtivo"
            name="boolAtivo"
            label="Exame ativo?"
            required
            rules="required"
          />
        </div>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
        <campos-personalizado
          :formData="formData"
          :key="'campo-' + $root.$session.versao"
          :intTipoModeloId="312"
          @salvar="(x) => (formData.arrayCamposPersonalizado = x)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CamposPersonalizado from "@/components/cadastro/formularioPersonalizado/formatar";
import {
  EdInputText,
  EdInputTextArea,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
  EdInputAutoComplete,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputTextArea,
    EdInputSelect,
    EdInputSwitch,
    EdInputTipo,
    EdInputAutoComplete,
    CamposPersonalizado,
  },

  mounted() {},
  created() {},
  data() {
    return {
      arrayEsocial: [],
      arrayOrdem: [
        { intId: 1, strNome: "1 - REFERENCIAL" },
        { intId: 2, strNome: "2 - SEQUENCIAL" },
      ],
      arrayInterpretacao: [
        { intId: 1, strNome: "1 - EE" },
        { intId: 2, strNome: "2 - SC" },
        { intId: 3, strNome: "3 - SC+" },
      ],
      boolLoadingEsocial: false,
    };
  },
  watch: {},
  methods: {},
};
</script>
